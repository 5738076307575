<template>
  <div id="moments">
    <!-- 修改弹框 -->
    <el-dialog
      v-if="momentsFormTitle === '动态修改'"
      :title="momentsFormTitle"
      width="900px"
      :visible.sync="momentsDialogVisible"
      :close-on-click-modal="false"
      @close="momentsDialogClose"
    >
      <el-form
        ref="momentsFormRef"
        :model="momentsForm"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="发布日期">
              <el-input v-model="momentsForm.createTime" readonly />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="所属营地">
              <el-input v-model="momentsForm.name" readonly />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="发表用户">
              <el-input v-model="momentsForm.username" readonly />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="动态内容" prop="text">
              <el-input v-model="momentsForm.text" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="动态图片" prop="pic">
              <el-image
                v-for="item in momentsForm.pic"
                :key="item"
                style="width: 200px; height: 200px"
                fit="contain"
                :src="item"
                :preview-src-list="[item]"
              />
            </el-form-item>
          </el-col>
          <el-col style="height: 62.8px">
            <el-form-item label="是否显示" prop="display">
              <el-radio-group v-model="momentsForm.display">
                <el-radio :label="1">
                  显示
                </el-radio>
                <el-radio :label="-1">
                  不显示
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col style="height: 62.8px">
            <el-form-item label="是否推荐" prop="isRecommend">
              <el-radio-group v-model="momentsForm.isRecommend">
                <el-radio :label="1">
                  推荐
                </el-radio>
                <el-radio :label="0">
                  不推荐
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="momentsDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="momentsFormSubmit">
          提 交
        </el-button>
      </div>
    </el-dialog>
    <!-- 详情弹框 -->
    <el-dialog
      v-if="momentsFormTitle === '动态详情'"
      :title="momentsFormTitle"
      width="900px"
      :visible.sync="momentsDialogVisible"
      :close-on-click-modal="false"
      @close="momentsDialogClose"
    >
      <el-form
        ref="momentsFormRef"
        :model="momentsForm"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="发布日期">
              <span>{{ momentsForm.createTime || '-' }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="所属营地">
              <span>{{ momentsForm.name || '-' }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="发表用户">
              <span>{{ momentsForm.username || '-' }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="动态内容" prop="text">
              <span>{{ momentsForm.text || '-' }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="动态图片" prop="pic">
              <el-image
                v-for="item in momentsForm.pic"
                :key="item"
                style="width: 200px; height: 200px"
                fit="contain"
                :src="item"
                :preview-src-list="[item]"
              />
            </el-form-item>
          </el-col>
          <el-col style="height: 62.8px">
            <el-form-item label="是否显示" prop="display">
              <span v-if="momentsForm.display === 1">显示</span>
              <span v-else-if="momentsForm.display === -1">不显示</span>
              <span v-else>不显示</span>
            </el-form-item>
            <el-form-item label="是否推荐" prop="isRecommend">
              <span v-if="momentsForm.isRecommend === 1">推荐</span>
              <span v-else-if="momentsForm.isRecommend === 0">不推荐</span>
              <span v-else>不推荐</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="momentsDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="momentsFormSubmit">
          提 交
        </el-button>
      </div>
    </el-dialog>
    <!-- 搜索区 -->
    <el-form inline size="small">
      <el-form-item label="动态内容">
        <el-input
          v-model="searchForm.text"
          placeholder="请输入动态内容"
          clearable
          @keyup.enter.native="handleSearch"
        />
        <el-input type="text" style="display: none" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 表格数据 -->
    <el-table
      :data="momentsPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="id" label="动态ID" width="80" />
      <el-table-column label="发布日期" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.createTime">{{ scope.row.createTime.substring(0, 10) }}</span>
          <span v-else>{{ '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="text" label="动态内容">
        <template slot-scope="scope">
          <span v-if="scope.row.text">{{ scope.row.text }}</span>
          <span v-else>{{ '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="campName" label="所属营地">
        <template slot-scope="scope">
          <span v-if="scope.row.campName">{{ scope.row.campName }}</span>
          <span v-else>{{ '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="动态发布用户">
        <template slot-scope="scope">
          <span v-if="scope.row.username">{{ scope.row.username }}</span>
          <span v-else>{{ '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="display" label="是否显示" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.display === 1">是</span>
          <span v-if="scope.row.display === -1">否</span>
        </template>
      </el-table-column>
      <el-table-column prop="isRecommend" label="是否推荐" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.isRecommend === 1">是</span>
          <span v-if="scope.row.isRecommend === 0">否</span>
        </template>
      </el-table-column>
      <el-table-column label="评论数量" width="80">
        <template slot-scope="scope">
          <el-button type="text" @click.stop="handleComment(scope.$index, scope.row)">
            {{ scope.row.commentCount }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-star-off"
            size="small"
            @click.stop="handleComment(scope.$index, scope.row)"
          >
            评论
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="momentsPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { selectMomentsInfo, updateMoments, selectMomentsList, deleteMoments } from '@/api/business/moments'

export default {
  data () {
    return {
      momentsDialogVisible: false,
      baseUrl: 'https://oss-quye.huahuihongxin.com/',
      momentsFormTitle: '',
      fileList: [],
      roleList: [],
      momentsForm: {
        id: '',
        createTime: '',
        text: '',
        momentsName: '',
        name: '',
        pic: '',
        username: '',
        display: '',
        isRecommend: '',
        remarks: '',
        reviewTime: '',
        reviewer: ''
      },
      momentsPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: '',
        id: '',
        text: '',
        campId: ''
      }
    }
  },
  created () {
    if (this.$route.params.campId) {
      this.searchForm.campId = this.$route.params.campId
    }
    selectMomentsList(this.searchForm).then((res) => {
      this.momentsPage = res
    })
  },
  methods: {
    // 点击搜索触发的回调
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMomentsList(this.searchForm).then((res) => {
        this.momentsPage = res
      })
    },
    //点击修改触发的回调
    handleUpdate (index, row) {
      this.momentsFormTitle = '动态修改'
      this.momentsDialogVisible = true
      this.selectMomentsInfoById(row.id)
    },
    // 修改当前页触发的回调
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMomentsList(this.searchForm).then((res) => {
        this.momentsPage = res
      })
    },
    // 每页展示的数据发生变化时触发的回调
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMomentsList(this.searchForm).then((res) => {
        this.momentsPage = res
      })
    },
    momentsFormSubmit () {
      if (this.momentsFormTitle === '认领详情') {
        this.momentsDialogVisible = false
        return
      }
      this.$refs.momentsFormRef.validate(async (valid) => {
        console.log('2123' + valid)
        if (valid) {
          console.log('2123' + this.momentsFormTitle === '修改')
          if (this.momentsFormTitle === '动态修改') {
            await updateMoments(this.momentsForm)
          }
          this.momentsPage = await selectMomentsList(this.searchForm)
          this.momentsDialogVisible = false
        }
      })
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMoments(row.id)
        if (this.momentsPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.momentsPage = await selectMomentsList(this.searchForm)
      })
    },
    // 点击详情触发的回调
    handleInfo (index, row) {
      this.momentsFormTitle = '动态详情'
      this.momentsDialogVisible = true
      this.selectMomentsInfoById(row.id)
    },
    handleComment (index, row) {
      this.$router.push('/comment/' + row.id)
    },
    momentsDialogClose () {
      this.$refs.momentsFormRef.resetFields()
      this.checkList = []
      this.fileList = []
    },
    selectMomentsInfoById (id) {
      selectMomentsInfo(id).then((res) => {
        this.momentsForm.id = res.list[0].id
        this.momentsForm.createTime = res.list[0].createTime
        this.momentsForm.text = res.list[0].text
        this.momentsForm.momentsName = res.list[0].momentsName
        this.momentsForm.username = res.list[0].wxUser.name
        if (res.list[0].camp) {
          this.momentsForm.name = res.list[0].camp.name
        } else {
          this.momentsForm.name = '-'
        }
        if (res.list[0].pic) {
          const picUrl = res.list[0].pic.split(',')
          this.momentsForm.pic = []
          for (let i = 0; i < picUrl.length; i++) {
            this.momentsForm.pic.push(this.baseUrl + picUrl[i])
          }
        }
        this.momentsForm.display = res.list[0].display
        this.momentsForm.isRecommend = res.list[0].isRecommend
      })
    }
  }
}
</script>

<style></style>
