import axios from '@/common/axios'
import qs from 'qs'

export function selectMomentsInfo (id) {
  return axios({
    method: 'get',
    url: '/moments/info',
    params: { id: id }
  })
}

export function selectMomentsList (query) {
  return axios({
    method: 'get',
    url: '/moments/dynamicListWeb',
    params: query
  })
}

export function updateMoments (data) {
  return axios({
    method: 'put',
    url: '/moments/updateDynamicSHow',
    data: qs.stringify(data)
  })
}

export function deleteMoments (id) {
  return axios({
    method: 'post',
    url: '/moments/Delete',
    data: qs.stringify({ id: id })
  })
}
